import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
// material
import { AppBar, Container, Dialog, Grid, IconButton, Toolbar, Typography } from '@mui/material';
// components
import Page from '../components/Page';

import AnnonceSelector from 'src/components/FormAnnonceList/AnnonceSelector';
import CloseIcon from '@mui/icons-material/Close';
import AnnonceDetails from 'src/sections/@dashboard/products/AnnonceDetails';
import { useParams } from 'react-router-dom';
import ShopProductCard from 'src/sections/@dashboard/products/ProductCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomSpinner from './CustomSpinner';

// ----------------------------------------------------------------------
const initialState = {
  selectedCategory: null,
  setSelectedCategory: () => undefined,
  filteredAttributes: null,
  setFilteredAttributes: () => undefined,
  date: null,
  setDate: () => undefined,
  numObject: null,
  setNumObject: () => undefined,
  email: null,
  setEmail: () => undefined,
  name: null,
  setName: () => undefined,
};
export const FoundContext = createContext(initialState);

export default function EcommerceShop({ status }) {
  const [selectedAnnonce, setSelectedAnnonce] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [foundAds, setFoundAds] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [date, setDate] = useState(false);
  const [numObject, setNumObject] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const { annonceID } = useParams() || null;
  const [hasMore, setHasMore] = useState(true);
  const [resultFilter, setResultFilter] = useState([]);
  const [count, setCount] = useState({
    prev: 0,
    next: 150,
  });
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchFoundAds(6);
  }, [status]);

  const haveMore = () => {
    if (current.length === resultFilter.length || current.length === foundAds.length) {
      setHasMore(false);
      return;
    }

    setCurrent(current.concat(resultFilter.slice(count.prev + 150, count.next + 150)));
    setCount((prevState) => ({
      prev: prevState.prev + 150,
      next: prevState.next + 150,
    }));
  };

  const handleClickOpenDialog = (data) => {
    setSelectedAnnonce(data);

    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const urlStatus = status !== undefined ? `?status=${status}` : '';

  const fetchFoundAds = async (id) => {
    setIsLoading(true);
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_PUBLIC_API}/customers/${id}/annonces${urlStatus}`)
        .then((response) => response.data);
      setFoundAds(data);
      setTotal(data.length);
      if (annonceID !== null) {
        makeFilter(data);
      } else {
        setCurrent(data.slice(count.prev, count.next));
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const makeFilter = (data = foundAds) => {
    const EmailFilter = data.filter((a) => {
      if (email !== '') {
        return a.conversations.some((c) => c.author.email.toLowerCase() === email.toLowerCase());
      }
      return a.id > 0;
    });

    const nameFilter = EmailFilter.filter((a) => {
      if (name !== '') {
        return a.conversations.some((c) =>
          name !== '' ? c.author.lastname.toLowerCase() === name.toLowerCase() : a.id > 0
        );
      }
      return a.id > 0;
    });

    const filteredAnnonces = nameFilter.filter((a) =>
      numObject
        ? a.id === parseInt(numObject)
        : a.id > 0 && annonceID
        ? a.id === parseInt(annonceID)
        : a.id > 0 && selectedCategory
        ? a.category.id === selectedCategory
        : a.category.id > 0 && email && a.conversations.length > 0
        ? a.conversations[0].author.email === email
        : a.id > 0
    );

    const filteredAnnonceByDate = filteredAnnonces.filter((a) =>
      date ? date.setHours(0, 0, 0, 0) === new Date(a.date).setHours(0, 0, 0, 0) : new Date(a.date) > 0
    );

    const finalFilter = filteredAnnonceByDate.filter((a) => {
      return filteredAttributes.every((attribute) => {
        return a.annonceAttributes.some((a) =>
          attribute.attributeValue !== -1
            ? a.attribute === attribute.attribute &&
              a.attributeValue.toLowerCase() === attribute.attributeValue.toLowerCase()
            : a.attribute != null
        );
      });
    });

    setResultFilter(finalFilter);
    setCurrent(finalFilter.slice(count.prev, count.next));
  };

  useEffect(() => {
    makeFilter();
  }, [selectedCategory, filteredAttributes, date, numObject, name, email]);

  return (
    <Page title="Dashboard: Annonces">
      <FoundContext.Provider
        value={{
          selectedCategory,
          setSelectedCategory,
          filteredAttributes,
          setFilteredAttributes,
          date,
          setDate,
          numObject,
          setNumObject,
          name,
          setName,
          email,
          setEmail,
        }}
      >
        <Container>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            Objets
          </Typography>

          <AnnonceSelector />
          <Typography>{resultFilter.length} Résultats</Typography>
          <InfiniteScroll dataLength={current.length} next={haveMore} hasMore={hasMore} loader={<>chargement...</>}>
            {isLoading ? (
              <CustomSpinner />
            ) : (
              <Grid container spacing={3}>
                {current &&
                  current.map((ads) => (
                    <Grid key={ads.id} item xs={12} sm={6} md={4} sx={{ cursor: 'pointer' }}>
                      <ShopProductCard
                        ads={ads}
                        handleClickOpenDialog={handleClickOpenDialog}
                        fetchFoundAds={fetchFoundAds}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
          </InfiniteScroll>
          {/* <ProductList
            myAds={filteredAnnoncesByAttributes}
            handleClickOpenDialog={handleClickOpenDialog}
            fetchFoundAds={fetchFoundAds}
          /> */}
        </Container>

        {/* Annonce dialogue */}
        <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}>
          <AppBar sx={{ position: 'relative', backgroundColor: 'transparent' }}>
            <Toolbar>
              <IconButton
                edge="start"
                onClick={handleCloseDialog}
                aria-label="close"
                sx={{
                  color: '#1B3038',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <AnnonceDetails selectedAnnonce={selectedAnnonce} />
        </Dialog>
      </FoundContext.Provider>
    </Page>
  );
}

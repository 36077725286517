import {useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { Grid, Typography } from '@mui/material';
import Container from "@mui/material/Container";
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const ButtonStyle = styled('button')(({ theme }) => ({
  textAlign: 'center',
  padding: '50px',
  backgroundColor: '#e7f2f3',
  borderRadius: '20px',
  border: 'none',
  textTransform: 'uppercase',
  minWidth: '100%',
  '&:hover': {
    backgroundColor: "#009faa",
    color: "white"
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);

  useEffect(() => {
      if (window.location.href == `${process.env.REACT_APP_BASEURL}/dashboard/publish`) {
        setIsActive1(true);setIsActive2(false);setIsActive3(false);setIsActive4(false)
      } else if (window.location.href == `${process.env.REACT_APP_BASEURL}/dashboard/products/trouves`) {
        setIsActive2(true);setIsActive1(false);setIsActive3(false);setIsActive4(false)
      } else if (window.location.href == `${process.env.REACT_APP_BASEURL}/dashboard/messages`) {
        setIsActive3(true);setIsActive1(false);setIsActive2(false);setIsActive4(false)
      } else {
        setIsActive4(true);setIsActive1(false);setIsActive2(false);setIsActive3(false)
      }
  });

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Container>
          <Grid sx={12} container spacing={3}>
            <Grid item md={3}>
              <ButtonStyle
              onClick={() => navigate('/dashboard/publish') }
              style={{
                backgroundColor: isActive1 ? '#009faa' : '#e7f2f3',
                color: isActive1 ? 'white' : 'black',
              }}>
                Publier
              </ButtonStyle>
            </Grid>
            <Grid item md={3}>
              <ButtonStyle
                  onClick={() => navigate('/dashboard/products/trouves')}
                  style={{
                backgroundColor: isActive2 ? '#009faa' : '#e7f2f3',
                color: isActive2 ? 'white' : 'black',
              }}>
                Objets trouvés
              </ButtonStyle>
            </Grid>
            <Grid item md={3}>
              <ButtonStyle
                  onClick={() => navigate('/dashboard/messages')}
                  style={{
                    backgroundColor: isActive3 ? '#009faa' : '#e7f2f3',
                    color: isActive3 ? 'white' : 'black',
                  }}>
                Messages reçus
              </ButtonStyle>
            </Grid>
            <Grid item md={3}>
              <ButtonStyle
                  onClick={() => navigate('/dashboard/products/aexpedie')}
                  style={{
                    backgroundColor: isActive4 ? '#009faa' : '#e7f2f3',
                    color: isActive4 ? 'white' : 'black',
                  }}>
                A expedier
              </ButtonStyle>
            </Grid>
          </Grid>
        </Container>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

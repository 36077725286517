import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';

import AnnonceContext from '../../../../contexts/AnnonceContext';
import BoxSelector from './Forms/BoxSelector';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function Step5() {
  const { handleSubmit, formAnnonce } = useContext(AnnonceContext);

  return (
    <>
     {
      formAnnonce.status === "TROUVÉ" ? (
        <Box pb={5}>
            <BoxSelector />

            <FormControl component="fieldset">
                <FormLabel component="legend">Mode de restitution</FormLabel>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox />}
                        label="Par livraison"
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        value="bottom"
                        control={<Checkbox />}
                        label="En main propre"
                        labelPlacement="start"
                    />
                </FormGroup>
            </FormControl>
        </Box>
      ) : (
        <Typography variant="h5">
          Pas de Stockage pour les Objets Perdus
        </Typography>
      )

    
     }
     
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Publiez votre annonce
      </Button>
    </>
  );
}

import React, { useContext, useState } from 'react';
import AnnonceContext from '../../../../contexts/AnnonceContext';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  FormControl,
  FormControlLabel,
  FormLabel, Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Select, Paper
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DaySelector from './Forms/DaySelector';

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useEffect } from 'react';
import Container from "@mui/material/Container";

export default function Step1() {
  const { formAnnonce, setFormAnnonce, date, setDate } = useContext(AnnonceContext);
  const [isLost, setIsLost] = useState();
  const handleChangeDate = (newDate) => {
    setDate(newDate);
    setFormAnnonce({ ...formAnnonce, date: newDate.toISOString() });
  };

  useEffect (() => {
    setIsLost(formAnnonce.status === "PERDU" ? true : false);
  },[])

  const handleStatus = (e) => {
    setFormAnnonce({ ...formAnnonce, status: e.target.value, origin: e.target.value });
    setIsLost(e.target.value === "PERDU" ? true : false);
  };

  return (
    <>
      <Stack spacing={2}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="status-radio-buttons-group"
            name="status-radio-buttons-group"
            value={formAnnonce.status}
            onChange={handleStatus}
          >
            <FormControlLabel
              value="TROUVÉ"
              control={<Radio checkedIcon={<SentimentSatisfiedAltIcon />} />}
              label="Trouvé"
            />
            <FormControlLabel
              value="PERDU"
              control={<Radio checkedIcon={<SentimentVeryDissatisfiedIcon />} />}
              label="Perdu"
            />
          </RadioGroup>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Quand ?"
            inputFormat="dd/MM/yyyy"
            value={date}
            onChange={(newDate) => {
              handleChangeDate(newDate);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <DaySelector setDate={setDate} date={date} />
        <Typography variant="h4" sx={{ marginTop: 5, marginBottom: 5 }}>
          Où ?
        </Typography>
        <Grid sx={12} container spacing={3}>
          <Grid item md={4}>
            <TextField id="outlined-basic" label="Ville / Adresse" variant="outlined" />
          </Grid>
          <Grid item md={4}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                defaultValue={10}
            >
              <MenuItem value={10}>Transports ?</MenuItem>
              <MenuItem value={20}>Bus</MenuItem>
              <MenuItem value={30}>Métro</MenuItem>
              <MenuItem value={40}>Scolaire</MenuItem>
            </Select>
          </Grid>
          <Grid item md={4}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                defaultValue={10}
            >
              <MenuItem value={10}>Ligne</MenuItem>
              <MenuItem value={20}>A</MenuItem>
              <MenuItem value={30}>B</MenuItem>
              <MenuItem value={40}>C1</MenuItem>
              <MenuItem value={50}>C2</MenuItem>
              <MenuItem value={60}>C3</MenuItem>
              <MenuItem value={70}>C4</MenuItem>
              <MenuItem value={80}>C5</MenuItem>
              <MenuItem value={90}>C6</MenuItem>
            </Select>
          </Grid>
        </Grid>

        {isLost && 
        
        <TextField
      
        required
        id="contact"
        label="Tél ou Email Personne à Contacter"
        defaultValue={formAnnonce.contact}
        onChange={(e) => setFormAnnonce({ ...formAnnonce, contact: e.target.value })}
      />
        
        }
      </Stack>
    </>
  );
}
